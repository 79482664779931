// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-customer-invoices-js": () => import("./../../../src/pages/customer-invoices.js" /* webpackChunkName: "component---src-pages-customer-invoices-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-edi-js": () => import("./../../../src/pages/edi.js" /* webpackChunkName: "component---src-pages-edi-js" */),
  "component---src-pages-id-js": () => import("./../../../src/pages/id.js" /* webpackChunkName: "component---src-pages-id-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-payments-p-27-js": () => import("./../../../src/pages/payments-p27.js" /* webpackChunkName: "component---src-pages-payments-p-27-js" */),
  "component---src-pages-portal-js": () => import("./../../../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-pages-sdc-js": () => import("./../../../src/pages/sdc.js" /* webpackChunkName: "component---src-pages-sdc-js" */),
  "component---src-pages-splash-js": () => import("./../../../src/pages/splash.js" /* webpackChunkName: "component---src-pages-splash-js" */),
  "component---src-pages-supplier-invoices-e-invoice-js": () => import("./../../../src/pages/supplier-invoices/e-invoice.js" /* webpackChunkName: "component---src-pages-supplier-invoices-e-invoice-js" */),
  "component---src-pages-supplier-invoices-index-js": () => import("./../../../src/pages/supplier-invoices/index.js" /* webpackChunkName: "component---src-pages-supplier-invoices-index-js" */),
  "component---src-pages-supplier-invoices-invoice-control-js": () => import("./../../../src/pages/supplier-invoices/invoice-control.js" /* webpackChunkName: "component---src-pages-supplier-invoices-invoice-control-js" */),
  "component---src-pages-supplier-invoices-pdf-invoices-js": () => import("./../../../src/pages/supplier-invoices/pdf-invoices.js" /* webpackChunkName: "component---src-pages-supplier-invoices-pdf-invoices-js" */),
  "component---src-pages-supplier-invoices-scanning-js": () => import("./../../../src/pages/supplier-invoices/scanning.js" /* webpackChunkName: "component---src-pages-supplier-invoices-scanning-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-webinar-js": () => import("./../../../src/pages/webinar.js" /* webpackChunkName: "component---src-pages-webinar-js" */)
}

